export function removeElementFromDOM () {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const nativeElement: any = document.getElementById('init-loader')
  nativeElement.remove()
}

function padTo2Digits (num: number) {
  return num.toString().padStart(2, '0')
}

const month = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec'
]

export function formatDate (date: any) {
  return [
    padTo2Digits(date.getDate()),
    month[date.getMonth()],
    date.getFullYear()
  ].join('-')
}

export function formatDateNoDash (date: any) {
  return [
    padTo2Digits(date.getDate()),
    month[date.getMonth()],
    date.getFullYear()
  ].join(' ')
}
